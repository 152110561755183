.font-dark {
    color: black !important;
}
.backgroud {
    background-color: #030711 !important;
}
.main-background {
    background-color: #0c0b0e !important;
    background-image: url("../src/home-main-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}
.font-primary {
    color: #B4DAF6 !important;
}
.featured-icon-box:hover,
.featured-icon-box:hover .featured-content i,
.featured-icon-box:hover .featured-content a,
.featured-icon-box:hover .featured-content .featured-desc p,
.featured-icon-box:hover .featured-content .featured-title h5 {
    color: white;
}

.backgroud-primary,.backgroud-primary-hover{
    background-color: #B4DAF6;
}
.backgroud-primary-hover:hover{
    background-color: #B4DAF6;
}

#profileImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #B4DAF6;
    font-size: 30px;
    color: black;
    text-align: center;
    line-height: 80px;
  }

.width-37 {
    width: 37px;
}

.portfolio-img-container {
    height: 250px;
    display: flex;
}
.portfolio-img {
    width: 80%;
    display: block;
}

.font-white{
    color: white;
}

.text-transform-lower{
    text-transform: lowercase !important;

}

.center{
    margin: auto;
}
.center-horizontally{
    margin: 0 auto;
}
.center-vertically{
    margin: auto 0;
}
.line-h-35{
    line-height: 35px;
}
.contact-form{
    width:100%;height:600px;
}
.font-highlight{
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Playfair Display', serif;
    letter-spacing: 1.2px;
}

.lottie-div{
        position: absolute;
        z-index: 2;
}

